.main-app {
  padding: 33px 1.5em 4em;
}

main {
  margin-top: 5em;
}
header img {
  width: 80px;
}
.description {
  text-align: center;
}
.description h1 {
  font-size: 28px;
  line-height: 34px;
}

.description h2 {
  font-size: 16px;
}

.img-done {
  margin-bottom: 2em;
}
.upload {
  margin-top: 4em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.upload label {
  margin-top: 5px;
}
.download {
  margin-top: 41px;
  text-align: center;
}
.upload-box label {
  font-size: 16px;
  margin-top: 25px;
  display: block;
}
.upload-box {
  border: 3px dashed #252525;
  border-radius: 30px;
  width: calc(320px - 42px);
  height: 248px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uploaded-image {
  width: calc(320px - 42px);
  height: 248px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}
.uploaded-image img {
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* button {
  background-color: #fbb540;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
} */

.btn {
  padding: 10px 25px;
  border-radius: 10px;
  border: none;
  font-weight: 700;
  cursor: pointer;
}

.btn-primary {
  background-color: #fbb540;
  color: white;
}
.btn-secondary {
  background-color: #252525;
  color: white;
}
.mr-50 {
  margin-right: 50px;
}

a:hover {
  color: white;
}

footer {
  text-align: center;
  margin-bottom: 2em;
}
footer h2 {
  font-size: 12px;
}

@media (min-width: 1200px) {
  header img {
    width: 142px;
  }
  .main-app {
    max-width: 90%;
    margin: 0 auto;
  }
  main {
    margin-top: 5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .description h1 {
    font-size: 48px;
    line-height: 64px;
  }

  .description h2 {
    font-size: 30px;
  }

  .upload-box {
    width: 495px;
    height: 348px;
  }
  .upload-box label {
    font-size: 30px;
  }
  .uploaded-image {
    width: 495px;
    height: 348px;
    /* flex-direction: column; */
  }
}

@media (min-width: 1366px) {
  .main-app {
    max-width: 1322px;
    margin: 0 auto;
  }
}

/* Input File Button */
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  /* z-index: 99; */
}
